import { http } from "./http";
import { TYPE_BREEDS_API, CREATE_QOUTES_API, CREATE_REMINDER_API, DELETE_REMINDER_API, UPLOAD_DOCS_API, GET_DOCS_API, LOGIN_API, USER_PETS_API, FORGET_PASSWORD_API, DELETE_DOCS_API, EDIT_DOCS_API, GET_REMINDER_API, EDIT_REMINDER_API, UPLOAD_PET_IMAGE_API, UPCOMING_REMINDERS, GET_PLANS, EMIRATES_ID_API, GENRATE_PRICE, USER_DETAILS, PET_UPDATE, UPLOAD_USER_IMAGE_API, CREATE_CLAIM, UPLOAD_CLAIM_DOCS_API, GET_CLAIMS_API, FETCH_VETS, MAKE_PAYMENT, VALIDATE_QOUTES_API, GET_PAYMENT_STATUS, CREATE_QOUTES_WITHOUT_PAYMENT_API, CREATE_LEAD, UPDATE_LEAD, GET_LEAD_DETAILS, FETCH_VETS_DETAILS, USER_POLICIES_API, GET_USER_IMAGE, RESET_PASSWORD_API, GET_POLICIES_ADMIN, GET_POLICY_BY_ID_ADMIN, SAVE_POLICY_DOCUMENT_SENT_BY_ID_ADMIN, SAVE_PET_TAG_SENT_BY_ID_ADMIN, GET_CLAIMS_ADMIN, GET_CLAIM_BY_ID_ADMIN, INITIATE_CLAIM_BY_ID_ADMIN, INITIATE_CALCULATE_CLAIM_BY_ID_ADMIN, SEARCH_POLICY_BY_ID_ADMIN, SAVE_POLICY_CANCEL_REQUEST, SAVE_POLICY_CHANGE_REQUEST, SAVE_VET_DEMO_REQUEST, SEND_EMAIL_MESSAGE, GET_VETS, ASSIGN_VET_TO_CLAIM, SAVE_VET_AND_ASSIGN_TO_CLAIM, INITIATE_A_CALL, SEND_LEAD_MAIL, GET_LEAD_DETAILS_BY_ID, SEARCH_LEADS, MAKE_PAYMENT_TAMARA, GET_TAMARA_PAYMENT_STATUS, SAVE_CLAIM_VET_IDENTIFIED_REASON, SAVE_PET_PRE_EXISTING_CONDITIONS, GET_CLAIMS_AND_POLICY_HEALTH_INFO, SEARCH_CLAIMS_BY_ADMIN, GET_CURRENT_POLICY_BY_USERID_PETID, MAKE_PAYMENT_BROKER, SYNC_BROKER_PAYMENT_STATUS } from "./constants";

export const getPetBreeds = async () => {
    return await http.get(TYPE_BREEDS_API)
}
export const login = async (data) => {
    return http.post(LOGIN_API, data)
}
export const getUserPets = async () => {
    return http.get(USER_PETS_API)
}
export const getUserPolicies = async () => {
    return http.get(USER_POLICIES_API)
}
export const forgetPassword = async (data) => {
    return http.get(FORGET_PASSWORD_API+'/'+data)
}
export const resetPasswordCheck = async (data) => {
    return http.get(RESET_PASSWORD_API+'/'+data)
}
export const resetPassword = async (data) => {
    return http.put(RESET_PASSWORD_API, data)
}
export const uploadDocs = async (data,config) => {
    return http.post(UPLOAD_DOCS_API,data,config)
}
export const getDocs = async (data,config) => {
    return http.get(GET_DOCS_API)
}
export const deleteDocs = async (ids) => {
    return http.delete(DELETE_DOCS_API+'/'+ids)
}
export const editDocs = async (data) => {
    return http.put(EDIT_DOCS_API, data)
}
export const createReminder = async (data) => {
    return http.post(CREATE_REMINDER_API, data)
}
export const editReminder = async (data) => {
    return http.put(EDIT_REMINDER_API, data)
}
export const getReminder = async (id='') => {
    return http.get(GET_REMINDER_API+'/'+id)
}
export const deleteReminder = async (id) => {
    return http.delete(DELETE_REMINDER_API+'/'+id)
}
export const uploadPetImage = async (id,data,config) => {
    return http.post(UPLOAD_PET_IMAGE_API+'/'+id,data,config)
}
export const getUserImage = async (fileKey) => {
    return http.get(GET_USER_IMAGE+'/'+fileKey)
}
export const uploadUserImage = async (id,data,config) => {
    return http.post(UPLOAD_USER_IMAGE_API+'/'+id,data,config)
}
export const createQoute = async (data) => {
    return http.post(CREATE_QOUTES_API,data)
}
export const createWithoutPayment = async (data) => {
    return http.post(CREATE_QOUTES_WITHOUT_PAYMENT_API,data)
}
export const validateQoute = async (data) => {
    return http.post(VALIDATE_QOUTES_API,data)
}
export const makePayment = async (data) => {
    return http.post(MAKE_PAYMENT,data)
}
export const makePaymentViaTamara = async (data) => {
    return http.post(MAKE_PAYMENT_TAMARA,data)
}
export const makePaymentBroker = async (data) => {
    return http.post(MAKE_PAYMENT_BROKER,data)
}
export const syncBrokerPaymentStatus = async (orderRef) => {
    return http.get(SYNC_BROKER_PAYMENT_STATUS+'/'+orderRef)
}
export const getPaymentStatus = async (orderRef) => {
    return http.get(GET_PAYMENT_STATUS+'/'+orderRef)
}
export const getTamaraPaymentStatus = async (orderRef) => {
    return http.get(GET_TAMARA_PAYMENT_STATUS+'/'+orderRef)
}
export const getUpcomingReminders = async () => {
    return http.get(UPCOMING_REMINDERS)
}
export const getPlans = async () => {
    return http.get(GET_PLANS)
}
export const getUserDetails = async () => {
    return http.get(USER_DETAILS)
}
export const uploadIds = async (data,config) => {
    return http.post(EMIRATES_ID_API,data,config)
}
export const generatePrice = async (data) => {
    return http.post(GENRATE_PRICE,data)
}
export const updateUser = async (data) => {
    return http.put(USER_DETAILS,data)
}
export const updatePet = async (data) => {
    return http.put(PET_UPDATE,data)
}
export const createClaim = async (data) => {
    return http.post(CREATE_CLAIM,data)
}
export const updateClaim = async (data) => {
    return http.put(CREATE_CLAIM,data)
}
export const uploadClaimDocs = async (id,data,config) => {
    return http.post(UPLOAD_CLAIM_DOCS_API+'/'+id,data,config)
}
export const getClaims = async (id) => {
    return http.get(GET_CLAIMS_API+'/'+id)
}
export const searchVet = async (lat,lng,key,rad) => {
    return http.get(FETCH_VETS+'/'+lat+'/'+lng+'/'+key+'/'+rad)
}
export const searchVetDetails = async (placeId) => {
    return http.get(FETCH_VETS_DETAILS+'/'+ placeId)
}
export const searchLeads = async (email) => {
    return http.get(SEARCH_LEADS+'/'+ email)
}
export const createLead = async (data) => {
    return http.post(CREATE_LEAD,data)
}
export const updateLead = async (data) => {
    return http.post(UPDATE_LEAD,data)
}
export const sendLeadEmail = async (data) => {
    return http.post(SEND_LEAD_MAIL,data)
}
export const getLead = async (email) => {
    return http.get(GET_LEAD_DETAILS+'/'+email)
}
export const getLeadById = async (leadId) => {
    return http.get(GET_LEAD_DETAILS_BY_ID+'/'+leadId)
}
export const getPoliciesForAdmin = async (status) => {
    return http.get(GET_POLICIES_ADMIN+'/'+status)
}
export const getPolicyByIdForAdmin = async (id) => {
    return http.get(GET_POLICY_BY_ID_ADMIN+'/'+id)
}
export const searchPolicyByKeywordForAdmin = async (keyword) => {
    return http.get(SEARCH_POLICY_BY_ID_ADMIN+'/'+keyword)
}
export const SavePolicyDocumentSentStatusByIdForAdmin = async (data) => {
    return http.post(SAVE_POLICY_DOCUMENT_SENT_BY_ID_ADMIN, data)
}
export const SavePetTagSentStatusByIdForAdmin = async (data) => {
    return http.post(SAVE_PET_TAG_SENT_BY_ID_ADMIN, data)
}
export const getClaimsForAdmin = async (status, page, pageSize) => {
    return http.get(GET_CLAIMS_ADMIN+'/'+status+'/'+page+'/'+pageSize)
}
export const searchClaimsForAdmin = async (keyword, page, pageSize) => {
    return http.get(SEARCH_CLAIMS_BY_ADMIN+'/'+keyword+'/'+page+'/'+pageSize)
}
export const getClaimByIdForAdmin = async (id) => {
    return http.get(GET_CLAIM_BY_ID_ADMIN+'/'+id)
}
export const InitiateClaimByIdForAdmin = async (id) => {
    return http.get(INITIATE_CLAIM_BY_ID_ADMIN+'/'+id)
}
export const InitiateCalculateClaimByIdForAdmin = async (data) => {
    return http.post(INITIATE_CALCULATE_CLAIM_BY_ID_ADMIN, data)
}
export const SaveClaimVetIdentifiedReason = async (data) => {
    return http.post(SAVE_CLAIM_VET_IDENTIFIED_REASON, data)
}
export const SavePolicyCancelRequest = async (data) => {
    return http.post(SAVE_POLICY_CANCEL_REQUEST, data)
}
export const SavePolicyChangeRequest = async (data) => {
    return http.post(SAVE_POLICY_CHANGE_REQUEST, data)
}
export const GetCurrentPolicyForClaimGraphs = async (userId, petId) => {
    return http.get(GET_CURRENT_POLICY_BY_USERID_PETID+'/'+userId+'/'+petId)
}
export const SaveDemoRequest = async (data) => {
    return http.post(SAVE_VET_DEMO_REQUEST, data)
}
export const GetVets = async () => {
    return http.get(GET_VETS)
}
export const AssignVetToClaim = async (data) => {
    return http.post(ASSIGN_VET_TO_CLAIM, data)
}
export const SaveVetAndAssignToClaim = async (data) => {
    return http.post(SAVE_VET_AND_ASSIGN_TO_CLAIM, data)
}
export const SendEmailMessage = async (data) => {
    return http.post(SEND_EMAIL_MESSAGE, data)
}
export const InitiateACall = async (data) => {
    return http.post(INITIATE_A_CALL, data)
}
export const SavePreExistingConditions = async (data) => {
    return http.post(SAVE_PET_PRE_EXISTING_CONDITIONS, data)
}
export const GetPolicyAndClaimHealthInfo = async (policyId) => {
    return http.get(GET_CLAIMS_AND_POLICY_HEALTH_INFO+'/'+policyId)
}