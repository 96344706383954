import React, { Component, Fragment } from 'react';
import Header from '../components/common/header'
import Footer from '../components/common/footer'

class mainLayout extends Component {
    constructor(props){
        super(props);
        this.state = {
            qouteRef : null
        }
    }
    divRef = React.createRef();

    componentDidMount = () => {
        this.divRef.current.scrollIntoView()
    }

    setQouteRef = (qouteRef) =>{
        this.setState({qouteRef})
    }
    onGetQouteClick = () =>{
        this.state.qouteRef.current.scrollIntoView()
    }
    render() {



        const childrenWithProps = (this.props.children && this.props.children.length) ? this.props.children.map(child => {
            // Checking isValidElement is the safe way and avoids a typescript
            // error too.
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { setQouteRef: this.setQouteRef });
            }
            return child;
        }) : this.props.children ;

        return (
            <Fragment>
                {/* <div > */}
                {!(this.props.hideMenu) && (
                    <Header qouteFlag={this.state.qouteRef} onGetQouteClick={this.onGetQouteClick}/>
                )}
                    <div className="main" ref={this.divRef}>
                        {childrenWithProps}
                    </div>
                    <Footer />
                {/* </div> */}
            </Fragment>
        );
    }
}

export default mainLayout;