import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Card, Typography, Space, Spin } from 'antd';
import oslologo from "../assets/images/oslo-badge-new.png";
import yallalogo from "../assets/images/yalla-logo.png";
import { makePaymentBroker } from '../api/pets-api';
import { popupMessages } from '../helpers/utilities';

const { Title } = Typography;

const PaymentSelection = () => {
    const { paymentid } = useParams(); // Get payment ID from the route
    const history = useHistory();

    const [isError, setIsError] = useState(false);
    
    useEffect(() => {
        if (paymentid) {
            processPayment(paymentid);
        }
    }, [paymentid]);

    const processPayment = async (paymentId) => {
        try {
            debugger;
            const payload = { id: paymentId };
            console.log("Invoking makePaymentBroker with:", payload);
            
            const response = await makePaymentBroker(payload);

            if (response && response.data && response.data.status === "ok" && response.data.result?.gatewaylink) {
                console.log("Redirecting to:", response.data.result.gatewaylink);
                window.location.href = response.data.result.gatewaylink; // Redirect to payment gateway
            } else {
                popupMessages("Payment failed or missing gateway link.", 'warning');
                setIsError(true);
            }
        } catch (error) {
            popupMessages("Error processing payment:", 'error');
            setIsError(true);
        }
    };

    return (
        <div className="payment-selection">
            <Card className="w-50 m-5" style={{ height: 'auto' }} bordered={true}>
                {/* <div className="full-width mt-5" align={"center"} justify={"space-between"} size={100} >
                    <Space style={{ margin: '0 auto' }} align={"center"} justify={"space-between"} size={100} className="text-center">
                        <img className="img-fluid" style={{ height: '100px' }} src={oslologo} alt="Oslo Logo" />
                        <img className="img-fluid" style={{ height: '50px' }} src={yallalogo} alt="Yalla Logo" />
                    </Space>
                </div> */}
                {
                    !isError &&
                    <>
                        <Title level={2} className="title full-width text-center p-5 pb-0 m-0">
                            Your payment is processing,
                        </Title>
                        <Title level={3} className="title full-width text-center m-0 mt-3">
                            Please wait and refrain from refreshing the page.
                        </Title>
                        <Space justify={"center"} align={'center'} style={{ justifyContent: 'center' }} size={30} className="full-width text-center mt-5">
                            <Spin />
                            <Spin delay={150} />
                            <Spin delay={300} />
                            <Spin delay={450} />
                        </Space>
                        <Title level={3} className="title full-width text-center m-0 mt-5">
                            You will be redirected to the payment gateway.
                        </Title>
                    </>
                }
                {
                    isError &&
                    <>
                        <Title level={2} className="title full-width text-center p-5 pb-0 m-0">
                            Your payment failed to process,
                        </Title>
                        <Title level={3} className="title full-width text-center m-0 mt-3">
                            Please contact support.
                        </Title>
                    </>
                }
                
                <div className='full-width mt-5 p-5 pb-0'>
                    <div className="bottom-footer full-width text-center">
                        <p className="text-center text-black-50">
                            The information contained in this website is for illustrative purposes only and coverage under any pet insurance policy is expressly subject to the terms and conditions of the policy documentation issued by the insurer.
                        </p>
                        <a href="https://www.alittihadalwatani.com/" target={'_blank'} rel="noreferrer" className="insurer-logo-holder">
                            <img className="img-fluid" src="/images/al-ittihad-al-watani-blue.jpg" alt="Al-Ittihad Al-Watani (AIAW)" />
                        </a>
                        <p className="mt-4 text-black-50">
                            © 2021 OSLO FZE, Dubai, United Arab Emirates - All Rights Reserved.
                        </p>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default PaymentSelection;