import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Typography, Space, Spin } from 'antd';
import oslologo from "../assets/images/oslo-badge-new.png";
import yallalogo from "../assets/images/yalla-logo.png";
import { syncBrokerPaymentStatus } from '../api/pets-api';
import { popupMessages } from '../helpers/utilities';

const { Title } = Typography;

const PaymentRespond = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [error, setError] = useState(null);

    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('ref'); // Retrieve 'ref' from query params

    useEffect(() => {
        if (ref) {
            checkPaymentStatus(ref);
        } else {
            setIsLoading(false);
            setError("Error: ref query param is required.");
            popupMessages("Error: ref query param is required.", "error");
        }
    }, [ref]);

    const checkPaymentStatus = async (reference) => {
        try {
            setIsLoading(true);
            const response = await syncBrokerPaymentStatus(reference);
            
            if (response?.data?.status === "ok" && response?.data?.result) {
                const { paymentMethod, state, amount, redirectUrl, paymentRef, leadId } = response.data.result;

                setRedirectUrl(redirectUrl); // Store the redirect URL

                if (paymentMethod) {
                    setPaymentDetails({
                        cardholder: paymentMethod.cardholderName || "N/A",
                        cardType: paymentMethod.name || "Unknown",
                        lastFour: paymentMethod.pan ? paymentMethod.pan.slice(-4) : "XXXX",
                        expiry: paymentMethod.expiry || "N/A",
                        amount: `${amount.currencyCode} ${(amount.value / 100).toFixed(2)}`, // Convert amount to readable format
                    });
                }

                if (state === "CAPTURED" || state === "FAILED") {
                    setPaymentStatus(state === "CAPTURED" ? "success" : "failed");
                    popupMessages(`Payment ${state === "CAPTURED" ? "successful" : "failed"}!`, state === "CAPTURED" ? "success" : "error");

                    // Wait 1 second before redirecting
                    setTimeout(() => {
                        submitPaymentRedirect(redirectUrl, paymentRef, leadId, state);
                    }, 1000);
                } else {
                    setPaymentStatus("pending");
                    popupMessages("Payment is still processing. Please wait...", "info");
                }
            } else {
                setError("Error: Invalid payment response.");
                popupMessages("Error: Invalid payment response.", "error");
            }
        } catch (error) {
            setError(`Error checking payment status: ${error.message}`);
            popupMessages(`Error checking payment status: ${error.message}`, "error");
        } finally {
            setIsLoading(false);
        }
    };

    const submitPaymentRedirect = (url, paymentRef, leadId, state) => {
        if (!url) return;

        // Create a hidden form
        const form = document.createElement("form");
        form.method = "POST";
        form.action = url;

        // Append required data as hidden fields
        const fields = { paymentRef, leadId, state };
        Object.entries(fields).forEach(([key, value]) => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = value;
            form.appendChild(input);
        });

        document.body.appendChild(form);
        form.submit(); // Submit the form automatically
    };

    return (
        <div className="payment-selection">
            <Card className="w-50 m-5" style={{ height: 'auto' }} bordered={true}>
                {/* <div className="full-width mt-5" align="center">
                    <Space style={{ margin: '0 auto' }} align="center" size={100} className="text-center">
                        <img className="img-fluid" style={{ height: '100px' }} src={oslologo} alt="Oslo Logo" />
                        <img className="img-fluid" style={{ height: '50px' }} src={yallalogo} alt="Yalla Logo" />
                    </Space>
                </div> */}

                {error ? (
                    <Title level={3} className="text-center text-danger">{error}</Title>
                ) : (
                    <>
                        <Title level={2} className="title full-width text-center p-5 pb-0 m-0">
                            {paymentStatus === "success" ? "Payment Successful!" :
                                paymentStatus === "failed" ? "Payment Failed!" :
                                    "Your payment is processing,"}
                        </Title>
                        <Title level={3} className="title full-width text-center m-0 mt-3">
                            {paymentStatus === "success" ? "You will receive a confirmation shortly." :
                                paymentStatus === "failed" ? "Please try again later." :
                                    "Please wait and refrain from refreshing the page."}
                        </Title>
                        <Space align="center" style={{ justifyContent: 'center' }} size={30} className="full-width text-center mt-5">
                            {isLoading ? (
                                <>
                                    <Spin />
                                    <Spin delay={150} />
                                    <Spin delay={300} />
                                    <Spin delay={450} />
                                </>
                            ) : paymentStatus === "failed" ? (
                                <Title level={4} className="text-center text-danger">Payment processing failed. Please try again.</Title>
                            ) : paymentStatus === "success" ? (
                                <>
                                    <Title level={4} className="text-center text-success">Payment was successful!</Title>
                                    {paymentDetails && (
                                        <div className="text-center mt-3">
                                            <p><strong>Paid Amount:</strong> {paymentDetails.amount}</p>
                                            <p><strong>Payment Method:</strong> {paymentDetails.cardType} (****{paymentDetails.lastFour})</p>
                                            <p><strong>Cardholder:</strong> {paymentDetails.cardholder}</p>
                                            <p><strong>Expiry:</strong> {paymentDetails.expiry}</p>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <Title level={4} className="text-center text-warning">Payment status unknown.</Title>
                            )}
                        </Space>
                        <Title level={3} className="title full-width text-center m-0 mt-5">
                            {paymentStatus === "success" ? "Thank you for your payment!" :
                                paymentStatus === "failed" ? "Please contact support if needed." :
                                    "You will be redirected to the partner's website."}
                        </Title>
                    </>
                )}

                <div className='full-width mt-5 p-5 pb-0'>
                    <div className="bottom-footer full-width text-center">
                        <p className="text-center text-black-50">
                            The information contained in this website is for illustrative purposes only and coverage under any pet insurance policy is expressly subject to the terms and conditions of the policy documentation issued by the insurer.
                        </p>
                        <a href="https://www.alittihadalwatani.com/" target={'_blank'} rel="noreferrer" className="insurer-logo-holder">
                            <img className="img-fluid" src="/images/al-ittihad-al-watani-blue.jpg" alt="Al-Ittihad Al-Watani (AIAW)" />
                        </a>
                        <p className="mt-4 text-black-50">© 2021 OSLO FZE, Dubai, United Arab Emirates - All Rights Reserved.</p>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default PaymentRespond;
