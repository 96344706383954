import React, { Fragment } from 'react';
import { Spin, Image, Typography, Button, Row, Col, Card, Form, Input, Select, Radio, DatePicker, Collapse, Tooltip, Space } from 'antd';
import { StarFilled } from '@ant-design/icons';
import { createLead } from '../../api/pets-api';
import { Link } from "react-router-dom";
import moment from 'moment';
import { CURRENCY, BenifitsMap } from '../../constants';
import { notifications } from '../../helpers/utilities';
import FeaturesTable from './features_table';
const { Paragraph, Title, Text } = Typography;
const { Panel } = Collapse;

const { Option } = Select;

class DogMainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            petTypeBreeds: [],
            petBreeds: [],
            plans: [],
            spin: false,
            disableBtn: false,
            dogId: '',
            type: 'Cat',
            genderBtnType: 'Male',
            petBtnType: 'Cat',
            petBtnClass: 'Cat',
            faqItems:[]
        }
        this.changeType = this.changeType.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
        // this.saveQoute = this.saveQoute.bind(this);
    }
    qouteRef = React.createRef()
    formRef = React.createRef();
    pageRef = React.createRef();
    mainRef = React.createRef();

    onGenderChange = (e) => {
        // console.log('change', e.target.value);
        this.setState({ genderBtnType: e.target.value })
    }
    onTypeChange = (e) => {
        // console.log('change', e.target.value);
        this.setState({ petBtnType: e.target.value })
        if (e.target.value === this.state.dogId) {
            this.setState({ petBtnClass: 'dog' })
        }else{
            this.setState({ petBtnClass: 'cat' })
        }
    }

    onDetailClick = () => {
        this.pageRef.current.scrollIntoView();
    }

    componentWillMount = () => {
        this.props.petTypeBreeds.map(type => {
            if (type.name == 'Cat') {
                this.setState({ dogId: type.id, petBreeds: type.Breads, type:'Cat' })
            }
        });
    }

    componentDidMount = () => {
        console.log('props child', this.props);
        
        
        this.props.setQouteRef(this.qouteRef)
        this.mainRef.current.scrollIntoView()
        // console.log('mount', this.props.petTypeBreeds);
        // this.props.petTypeBreeds.map(type => {
        //     if (type.name == 'Cat') {
        //         this.setState({ dogId: type.id, petBreeds: type.breeds })
        //     }
        // });
        this.setState({ petTypeBreeds: this.props.petTypeBreeds, plans: this.props.plans }, ()=>{
            
            if(this.props.location.pathname === "/ins"){
                this.goToQouteSection()
            }
        });

        const faqHomePageItems = [
            {
                question: "Can I visit any veterinarian? ( with my dog )",
                answer: "Of course ! We understand the importance of a trusting relationship between you and the vet. If you purchased your plan with OSLO, you may visit any licensed veterinarian in the UAE. This includes specialists and emergency.",
                hasChildItems: false
            },
            {
                question: "What is an annual deductible for pet insurance?",
                answer: "An annual deductible is the fixed amount per policy period (one year) that needs to be met before we would begin to pay benefits for covered claims, at the elected reimbursement amount. Only covered claims will be applied to your annual deductible. Your deductible will reset when your policy renews each year.",
                hasChildItems: false
            },
            {
                question: "What is a co-insurance for pet insurance?",
                answer: "Co-insurance is the percentage of the vet bill the pet parent is responsible for versus what OSLO will pay . With Oslo you have the option to choose between 70%, 80%, 90% . Keep in mind that this is applied to every claim.",
                hasChildItems: false
            },
            {
                question: "When does my policy start?",
                answer: "Oslo policies are effective at 12:01am standard time at your mailing address the day after enrollment.",
                hasChildItems: false
            },
            {
                question: "How do I file a claim? for my dog",
                answer: "Electronic/Paperless Claims Submission",
                hasChildItems: true,
                childItems: [
                    {
                        text: "Log into your Oslo Pet Cloud from any device."
                    },
                    {
                        text: "Select the “Send a claim” card and answer the online questions."
                    },
                    {
                        text: "Upload/attach a copy of your paid invoice and submit your claim."
                    },
                    {
                        text: "Have your veterinarian send us your pet’s medical records."
                    },
                    {
                        text: "You’re done!"
                    }
                ]
            },
            {
                question: "Can I pay on a monthly basis? ",
                answer: "Oslo is working with TAMARA where you can pay up to 4 installments. You will have the choice between annual premium or installment",
                hasChildItems: false
            },
            {
                question: "Can I get a sample of the insurance policy? (dog)",
                answer: "Yes sure at OSLO we are fully transparent. you can download our policy summary from our website.",
                hasChildItems: false
            },
            {
                question: "How do I add another pet?",
                answer: "Each pet needs its own policy and you will have to set each policy one at a time. If you’re buying the policies during your first purchase, please select “add a pet”. If you wish to purchase another policy after having purchased a policy for your first pet, just login to your Oslo Pet Cloud and hit “add a pet” in your account to get a new quote for your other furry friend – and get a 5% multi-pet discount!",
                hasChildItems: false
            }
        ];

        this.setState({faqItems: faqHomePageItems});
    }

    componentWillUpdate(prevProps) {

        if (this.props.petTypeBreeds !== prevProps.petTypeBreeds) {
            // if (this.state.spin) {
            //     console.log('update', this.props.petTypeBreeds);

            //     this.props.petTypeBreeds.map(type => {
            //         if (type.name == 'Cat') {
            //             this.setState({ dogId: type.id, petBreeds: type.Breads })
            //         }
            //     })
            //     this.setState({ spin: false })
            // }
            let typeBreeds = this.props.petTypeBreeds;
            typeBreeds.map(type => {
                let crossIndex = type.Breads.findIndex(breed => breed.name === 'Crossbreed');
                if (crossIndex !== -1) {
                    type.Breads.unshift(type.Breads.splice(crossIndex, 1)[0]);
                }
            })
            this.setState({ petTypeBreeds: typeBreeds })
        }
        if (this.props.plans !== prevProps.plans) {
            console.log('plans', this.props.plans);

            this.setState({ plans: this.props.plans });
        }
    }

    async onFinish(values) {

        let isPrimaryPet = this.props.addNewPet ? false : true;

        let selectedDate = values.age;
        let type = this.state.type;
        let ageMthDiff = Math.floor(moment().diff(selectedDate, 'months', true));
        let ageYrDiff = Math.floor(moment().diff(selectedDate, 'years', true));
        console.log('age different params', ageMthDiff, ageYrDiff, type);
         if (ageMthDiff > 96 && type === 'Dog') {
            let ageMsg = 'Oops, Oslo does not start new cover above 8 years old, please contact us at support@osloinsurance.pet for more info'
            notifications(ageMsg, 'Alert', 'error')
            this.setState({ disableBtn: true });
            return;
        } else if (ageMthDiff > 120 && type === 'Cat') {
            let ageMsg = 'Oups, Oslo does not start new cover above 10 years old, please contact us at support@osloinsurance.pet for more info'
            notifications(ageMsg, 'Alert', 'error')
            this.setState({ disableBtn: true });
            return;
        }

        console.log('cleicked')
        values = { ...values, age: moment(values.age).toDate(), isPrimaryPet: isPrimaryPet }
        console.log(values);
        let pPlanObj = {}
        pPlanObj = this.props.preQoute.hasOwnProperty('petsWithPlans') ? { petsWithPlans: this.props.preQoute.petsWithPlans } : { petsWithPlans: [] }
        pPlanObj.petsWithPlans.splice(pPlanObj.petsWithPlans.length, 0, { ...values, planId: 1, reimburseId: 1, deductionId: 1 });
        console.log('pPlanObj',pPlanObj);
        var respLead = await createLead(pPlanObj);
        if(respLead.data.error){
            notifications('Error occored while saving the lead', 'Alert', 'error')
        }else{
            
            this.props.setLeadId(respLead.data.result);
        }
        var transactionId = this.generateTransactionId();
        window.dataLayer = window.dataLayer || [];
        let rez = window.dataLayer.push({
        'event': 'conversion',
        'send_to': 'AW-11487089889/voQBCOSg2PkZEOGpvOUq',
        'value': 1.0,
        'currency': 'AED',
        'transaction_id': transactionId
        });
        console.log('GTAG Respo', rez)
        this.props.populateQoute(pPlanObj)
        this.props.changeFlag('qoute')
    };

    generateTransactionId() {
        return 'TXN-' + Date.now() + '-' + Math.floor(Math.random() * 10000);
    }

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };

    changeType(event) {
        let index = event.target.id;
        let type = event.target.petType;
        let petBreeds = this.state.petTypeBreeds[index].Breads
        this.setState({ petBreeds, type })
    }

    goToQouteSection = () => {
        window.scrollTo({
            top: 0, // Scrolls to the top of the page
            behavior: 'smooth', // Optional: Smooth scrolling effect
        });
    }

    ageChange = (selDate) => {
        this.setState({ disableBtn: false }, () => {
            let selectedDate = selDate;
            let type = this.state.type;
            let ageMthDiff = Math.floor(moment().diff(selectedDate, 'months', true));
            let ageYrDiff = Math.floor(moment().diff(selectedDate, 'years', true));
            console.log('age different params', ageMthDiff, ageYrDiff, type);
            if (ageMthDiff < 2) {
                let dateCopy = moment(selectedDate);
                let nextDate = dateCopy.add(2, 'M');
                console.log(ageMthDiff, nextDate);
                let ageMsg = 'At Oslo we love puppies and kitten, the effective date of your insurance will be 15th of ' + nextDate.format('MM') + '/' + nextDate.format('Y') + ' , for more info on age requirements please check our FAQ'
                notifications(ageMsg, 'Alert', 'warning')
            } else if (ageMthDiff > 96 && type === 'Dog') {
                let ageMsg = 'Oops, Oslo does not start new cover above 8 years old, please contact us at support@osloinsurance.pet for more info'
                notifications(ageMsg, 'Alert', 'error')
                this.setState({ disableBtn: true });
            } else if (ageMthDiff > 120 && type === 'Cat') {
                let ageMsg = 'Oups, Oslo does not start new cover above 10 years old, please contact us at support@osloinsurance.pet for more info'
                notifications(ageMsg, 'Alert', 'error')
                this.setState({ disableBtn: true });
            }
        });
    }

    render() {
        return (
            <div ref={this.mainRef}>
                <Fragment>
                    {
                        (!this.props.addNewPet && !this.props.renewPolicy) && 
                        <div>
                            <div className="banner doghome">
                                <Spin spinning={this.props.spinning} tip={this.props.tip} size="large">
                                <img
                                    className="img-fluid w-100"
                                    src="/images/banner-dog-landing.svg"
                                />
                                <div className="banner-caption p-0 add-top-margin-30 show-large">
                                    <div className="container">
                                        <Row gutter={15}>
                                            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                                                <Space direction="vertical" size={20}>
                                                    <div className='mt-5 img-logo-holder-home'>
                                                        <Link to={'/'}><Image
                                                            preview={false}
                                                            className="img-fluid"
                                                            alt='logo'
                                                            src="/images/oslo-logo-large.png"
                                                        /></Link>
                                                    </div>
                                                    <Title level={1} className="text-white bg-red-title add-top-margin-40 add-text-bold">Dog Insurance</Title>
                                                    <Text className="text-white follow-up-text">Let’s give them the best</Text>
                                                    <Text className="text-white follow-up-sub-text">Your Pet’s Health Can’t Wait - Sign Up Now for Peace of Mind</Text>
                                                </Space>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                                <Card className="card-form home-page-quote-holder add-top-margin-100" >
                                                    <Title level={2} className="text-green mb-3 text-center">Get a Quote Now!</Title>
                                                    <Form
                                                        name="basic"
                                                        onFinish={this.onFinish}
                                                        onFinishFailed={this.onFinishFailed}
                                                        size={"large"}
                                                        className="customise-form"
                                                        ref={this.formRef}
                                                    >
                                                        <Form.Item
                                                            name="name"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Please input pet's name!",
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder={"My Pet’s Name *"} />
                                                        </Form.Item>
                                                        <Row gutter={15} justify="center">
                                                            {!this.state.spin && <Col sm={12} md={12} lg={12} xl={12}>

                                                                <Spin spinning={this.state.spin}>
                                                                    <Form.Item
                                                                        className="text-center"
                                                                        name="type"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please select type! *',
                                                                            }
                                                                        ]}
                                                                        initialValue={this.state.dogId}
                                                                        onChange={this.onTypeChange}
                                                                    >

                                                                        {
                                                                            this.state.petTypeBreeds.length && <Radio.Group buttonStyle="solid" className={`btn-sliding` + ' ' + this.state.petBtnClass} onChange={this.changeType}>
                                                                                {
                                                                                    this.state.petTypeBreeds.map((type, index) =>
                                                                                        <Radio.Button key={index} value={type.id} id={index} petType={type.name}>{type.name}</Radio.Button>
                                                                                    )
                                                                                }
                                                                                {/* <Radio.Button value="cat">Cat</Radio.Button> */}
                                                                                <span className="switch-selection"></span>
                                                                            </Radio.Group>
                                                                        }
                                                                    </Form.Item>
                                                                </Spin>
                                                            </Col>}
                                                            <Col sm={12} md={12} lg={12} xl={12} className={this.state.genderBtnType}>
                                                                {/* value='Male' initialValue={'Male'} */}
                                                                <Form.Item className="text-center" name="gender"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please select gender! *',
                                                                        },
                                                                    ]}
                                                                    initialValue={"Male"}
                                                                    onChange={this.onGenderChange}>
                                                                    <Radio.Group buttonStyle="solid" className={`btn-sliding` + ' ' + this.state.genderBtnType.toLowerCase()}>
                                                                        <Radio.Button value="Male">Male</Radio.Button>
                                                                        <Radio.Button value="Female">Female</Radio.Button>
                                                                        <span className="switch-selection"></span>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Form.Item
                                                            name="age"
                                                            size={"large"}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Select Date of Birth (MM/YYYY)! *',
                                                                },
                                                            ]}
                                                        >
                                                            <DatePicker onChange={this.ageChange} style={{ width: '100%' }} placeholder="Select Date of Birth (MM/YYYY) *" format="MM/YYYY" picker="month" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="breed"
                                                            size={"large"}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please select breed! *',
                                                                },
                                                            ]}
                                                        >
                                                            <Select placeholder="Select breed *"
                                                                showSearch
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {
                                                                    this.state.petBreeds && (this.state.petBreeds.map((breed, index) =>
                                                                        <Option key={index} value={breed.id}>{breed.name}</Option>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                        {
                                                        !this.props.addNewPet &&            
                                                        <Form.Item
                                                            name="email"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input your email! *',
                                                                },
                                                                {
                                                                    type: 'email',
                                                                    message: 'Please input correct email!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Email *" />
                                                        </Form.Item>
                                                        }
                                                        <Form.Item className="text-center mt-5">
                                                            <Button disabled={this.state.disableBtn} type="primary" className="btn-red rounded-100" htmlType="submit">Continue</Button>
                                                        </Form.Item>
                                                    </Form>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="banner-caption p-0 show-on-small-screens">
                                    <div className="container">
                                        <Space direction="vertical" size={10} className='full-width'>
                                            <div className='mt-2 img-logo-holder-home-small'>
                                                <Link to={'/'}>
                                                    <img src="/images/oslo-logo-large.png" className='logo-img' alt="logo" />
                                                </Link>
                                                <Title level={3} className="text-white bg-red-title add-text-bold">Dog Insurance</Title>
                                            </div>
                                            <Text className="text-white follow-up-text-small ">Let’s give them the best</Text>
                                            <Text className="text-white follow-up-sub-text-small">Your Pet’s Health Can’t Wait - Sign Up Now for Peace of Mind</Text>
                                        </Space>
                                    </div>
                                </div>
                                </Spin>
                            </div>

                            <div className="full-width-section pt-5 full-width show-on-small-screens">
                                <div className="container">
                                    <Row gutter={[48, 20]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Card className="card-form home-page-quote-holder" >
                                                <Title level={2} className="text-green mb-3 text-center">Get a Quote Now!</Title>
                                                <Form
                                                    name="basic"
                                                    onFinish={this.onFinish}
                                                    onFinishFailed={this.onFinishFailed}
                                                    size={"large"}
                                                    className="customise-form"
                                                    ref={this.formRef}
                                                >
                                                    <Form.Item
                                                        name="name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input pet's name!",
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={"My Pet’s Name *"} />
                                                    </Form.Item>
                                                    <Row gutter={15} justify="center">
                                                        {!this.state.spin && <Col sm={12} md={12} lg={12} xl={12}>

                                                            <Spin spinning={this.state.spin}>
                                                                <Form.Item
                                                                    className="text-center"
                                                                    name="type"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please select type! *',
                                                                        }
                                                                    ]}
                                                                    initialValue={this.state.dogId}
                                                                    onChange={this.onTypeChange}
                                                                >

                                                                    {
                                                                        this.state.petTypeBreeds.length && <Radio.Group buttonStyle="solid" className={`btn-sliding` + ' ' + this.state.petBtnClass} onChange={this.changeType}>
                                                                            {
                                                                                this.state.petTypeBreeds.map((type, index) =>
                                                                                    <Radio.Button key={index} value={type.id} id={index} petType={type.name}>{type.name}</Radio.Button>
                                                                                )
                                                                            }
                                                                            {/* <Radio.Button value="cat">Cat</Radio.Button> */}
                                                                            <span className="switch-selection"></span>
                                                                        </Radio.Group>
                                                                    }
                                                                </Form.Item>
                                                            </Spin>
                                                        </Col>}
                                                        <Col sm={12} md={12} lg={12} xl={12} className={this.state.genderBtnType}>
                                                            {/* value='Male' initialValue={'Male'} */}
                                                            <Form.Item className="text-center" name="gender"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please select gender! *',
                                                                    },
                                                                ]}
                                                                initialValue={"Male"}
                                                                onChange={this.onGenderChange}>
                                                                <Radio.Group buttonStyle="solid" className={`btn-sliding` + ' ' + this.state.genderBtnType.toLowerCase()}>
                                                                    <Radio.Button value="Male">Male</Radio.Button>
                                                                    <Radio.Button value="Female">Female</Radio.Button>
                                                                    <span className="switch-selection"></span>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Form.Item
                                                        name="age"
                                                        size={"large"}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Select Date of Birth (MM/YYYY)! *',
                                                            },
                                                        ]}
                                                    >
                                                        <DatePicker onChange={this.ageChange} style={{ width: '100%' }} placeholder="Select Date of Birth (MM/YYYY) *" format="MM/YYYY" picker="month" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="breed"
                                                        size={"large"}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select breed! *',
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Select breed *"
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {
                                                                this.state.petBreeds && (this.state.petBreeds.map((breed, index) =>
                                                                    <Option key={index} value={breed.id}>{breed.name}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                    {
                                                    !this.props.addNewPet &&            
                                                    <Form.Item
                                                        name="email"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your email! *',
                                                            },
                                                            {
                                                                type: 'email',
                                                                message: 'Please input correct email!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Email *" />
                                                    </Form.Item>
                                                    }
                                                    <Form.Item className="text-center mt-5">
                                                        <Button disabled={this.state.disableBtn} type="primary" className="btn-red rounded-100" htmlType="submit">Continue</Button>
                                                    </Form.Item>
                                                </Form>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            
                            <div className="pet-insurance full-width bg-gray-light">
                                <div className="container">
                                    <Row gutter={[48, 20]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Card className='full-width p-3 landing-page-image-card'>
                                                <Text className="text-white">Why Pet Parents Trust OSLO with Their Furry Family Members?</Text>
                                            </Card>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Card className='full-width p-3 landing-page-info-card'>
                                                <Space direction="vertical" size={20}>
                                                    <Space size={20} align='center'>
                                                        <img src="/images/paws2.png" style={{borderRadius:'5px'}} alt="paws" />
                                                        <Text className="text-green">Plans for Every Budget</Text>
                                                    </Space>
                                                    <Space size={20} align='center'>
                                                        <img src="/images/paws2.png" style={{borderRadius:'5px'}} alt="paws" />
                                                        <Text className="text-green">Easy & Fast Claim Processing</Text>
                                                    </Space>
                                                    <Space size={20} align='center'>
                                                        <img src="/images/paws2.png" style={{borderRadius:'5px'}} alt="paws" />
                                                        <Text className="text-green">All Licensed Vets Accepted in the UAE</Text>
                                                    </Space>
                                                    <Space size={20} align='center'>
                                                        <img src="/images/paws2.png" style={{borderRadius:'5px'}} alt="paws" />
                                                        <Text className="text-green">4.9/5 Customer Ratings</Text>
                                                    </Space>
                                                    <Space size={20} align='center'>
                                                        <img src="/images/paws2.png" style={{borderRadius:'5px'}} alt="paws" />
                                                        <Text className="text-green">Flexible Payment</Text>
                                                        <img src="/images/tamara-en.png" className='tamara' alt="paws" />
                                                    </Space>
                                                    <Space size={20} align='center'>
                                                        <img src="/images/paws2.png" style={{borderRadius:'5px'}} alt="paws" />
                                                        <Text className="text-green">Lowest Waiting Period - 14 Days</Text>
                                                    </Space>
                                                </Space>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            
                            <div className="insurance-plans full-width home-section bg-gray-light">
                                <div className="container">
                                    <Title level={2} className="text-green text-center mb-5 section-title">Dog Insurance Plans</Title>
                                    <div className="full-width">
                                        <Row gutter={[48, 20]} align="middle" justify="center">
                                            <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                                                <Row gutter={[15, 15]} align="middle">
                                                    {
                                                        this.state.plans.length && this.state.plans.map((obj, index) => (
                                                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                                <Card className="plan-box">
                                                                    <Button className="btn-category btn-blue rounded-100">{obj.title}</Button>
                                                                    <div className="plan-tagline mt-5">AED {(BenifitsMap[obj.annualy].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))} Annual Benefits</div>
                                                                    <div className="plan-price text-center mt-4 ">
                                                                        <span >from</span>
                                                                    </div>
                                                                    <div className="plan-price text-center">
                                                                        <sub>{CURRENCY}</sub> <span className="price-amount">{(index) ? (index == 1) ? 77 : 89 : 50 }</span><sub>/month</sub>
                                                                    </div>
                                                                    <div className="what-we-cover full-width mt-4 text-center">
                                                                        <img src="/images/icon-warning-red.png" />
                                                                        <Title level={5} className="text-light-green mt-3">What we cover</Title>
                                                                    </div>
                                                                </Card>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>                                

                            <div className="pet-insurance full-width bg-gray-light">
                                <div className="container">
                                    <Title level={2} className="text-green text-center mb-5 section-title">How does OSLO work?</Title>
                                    <Row gutter={[48, 20]}>
                                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                            <div className="pet-insurance-box text-center">
                                                <Image
                                                    preview={false}
                                                    className="img-fluid"
                                                    src="/images/step-01.png"
                                                />
                                                <Title level={3} className="text-green font-700">Choose your plan & payment frequency</Title>
                                                <Paragraph>Select the coverage that best suits your pet’s needs.</Paragraph>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                            <div className="pet-insurance-box text-center">
                                                <Image
                                                    preview={false}
                                                    className="img-fluid"
                                                    src="/images/step-02.png"
                                                />
                                                <Title level={3} className="text-green font-700">Take your pet to the vets</Title>
                                                <Paragraph>Seek the best veterinary treatment for your pet when an accident or illness occurs.</Paragraph>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                            <div className="pet-insurance-box text-center">
                                                <Image
                                                    preview={false}
                                                    className="img-fluid"
                                                    src="/images/step-03.png"
                                                />
                                                <Title level={3} className="text-green font-700">Send us your claim</Title>
                                                <Paragraph>Send us a pic of your vet bill and submit it through our OSLO pet cloud.</Paragraph>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                            <div className="pet-insurance-box text-center">
                                                <Image
                                                    preview={false}
                                                    className="img-fluid"
                                                    src="/images/step-04.png"
                                                />
                                                <Title level={3} className="text-green font-700">Get your money back</Title>
                                                <Paragraph>Follow your claim status and get paid by direct transfer.  </Paragraph>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            
                            <div className="full-width-section p-5 full-width">
                                <div className="container">
                                    <Row gutter={[48, 20]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Title level={2} className="text-green mb-2 section-title text-center">The exact coverage level your pet needs</Title>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <div className="full-width bg-gray-light rounded-10 p-4">
                                                <Row gutter={[15, 15]} justify="space-evenly">
                                                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                                        <div className="points-toknow rounded-check">
                                                            <ul>
                                                                <li>Exams & Consultation Fees</li>
                                                                <li>Emergency & Hospitalization</li>
                                                                <li>Surgeries</li>
                                                                <li>Hereditary & Congenital conditions</li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                                        <div className="points-toknow rounded-check">
                                                            <ul>
                                                                <li>Chronic conditions</li>
                                                                <li>Cancer Treatments</li>
                                                                <li>Prescriptions</li>
                                                                <li>Veterinary Specialists</li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                                        <div className="points-toknow dot-center rounded-check">
                                                            <ul>
                                                                <li>Emergency Boarding Fees</li>
                                                                <li>Imaging</li>
                                                                <li>Knee conditions</li>
                                                                <li>Prosthetic & Orthotic devices</li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ marginTop: '-20px' }} className="full-width text-center mb-4">
                                                <Tooltip overlayClassName="tooltip-blue" color={'#57C6D6'} placement="right" title={<Fragment>
                                                    <Title level={4} className="text-white text-center">OSLO Assistance is included for free!</Title>
                                                    <Paragraph className="text-white mb-0">• Emergency boarding fees</Paragraph>
                                                    <Paragraph className="text-white mb-0">• Lost pet</Paragraph>
                                                    <Paragraph className="text-white mb-0">• Pet death</Paragraph>
                                                    <Paragraph className="text-white mb-0">• Vacation cancellation</Paragraph>
                                                </Fragment>}>
                                                    <img
                                                        className="img-fluid"
                                                        src="/images/iocn-gift.png"
                                                    />
                                                </Tooltip>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            
                            <div className="full-width-section p-5 full-width bg-gray-light">
                                <div className="container">
                                    <Row gutter={[48, 20]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Card className='full-width p-3 landing-page-today-offer-card'>
                                                <Row gutter={[15, 15]}>
                                                    <Col xs={24} sm={24} md={16} lg={14} xl={14}>
                                                        <Title level={1} className="text-white mt-3">Sign Up Today for Dog insurance and Get A Free Welcome Kit worth AED 300</Title>
                                                        <Text className="text-white text-size-20">Treat your furry friend to a special welcome kit packed with premium goodies like treats, toys, and grooming essentials. Your pet deserves the best, and we’re here to make it happen!</Text>
                                                        <Button type="primary" className="btn-red mt-5" style={{display:'block'}} onClick={this.goToQouteSection}>Get a Quote in Just 3 Clicks</Button>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={8} lg={10} xl={10}>
                                                        <img src="/images/dog-welcome-kit-1.png" className="img-fluid" alt="offer" />
                                                    </Col>
                                                </Row>   
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            
                            <div className="pet-insurance full-width">
                                <div className="container">
                                    <Title level={2} className="text-green text-center mb-0 section-title">Hear from real pet parents who love OSLO!</Title>
                                    <Title level={3} className="text-red text-center mb-3 mt-2">Rated 4.9/5 by Pet Parents Across the UAE</Title>
                                    <div size={20} className="text-center mb-5">
                                        <StarFilled style={{ fontSize: '30px' }} className='me-2 text-color-yellow' /> 
                                        <StarFilled style={{ fontSize: '30px' }} className='me-2 text-color-yellow' /> 
                                        <StarFilled style={{ fontSize: '30px' }} className='me-2 text-color-yellow' /> 
                                        <StarFilled style={{ fontSize: '30px' }} className='me-2 text-color-yellow' /> 
                                        <StarFilled style={{ fontSize: '30px' }} className='text-color-yellow'/> 
                                    </div>
                                    <Row gutter={[48, 20]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Card className="full-width testimonial-image-card" style={{background:"url('/images/Dog-Parent-to-Bella.png')"}} >
                                            </Card>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Card className="full-width testimonial-image-card testimonial-content bg-blue p-4" style={{background:"url('/images/buttom-cta.png')"}} align="center" justify="center">
                                                <Space direction="vertical" className='mt-5' size={20}>
                                                    <div className='text-center'>
                                                        <img src="/images/quote-for-testimonials.png" className="img-fluid" alt="quote" />
                                                    </div>
                                                    <Paragraph className="text-white mt-3 mb-2 text-size-20">Our dog required a CT scan & urgent surgery costing AED 20,000 for a kidney tumor. We submitted tests, invoices, and proof, & the claim was reimbursed in 5 days. Excellent service and great benefits!</Paragraph>
                                                    <Paragraph className="text-white mt-0 text-size-20 add-text-bold">Dog Parent to Bella</Paragraph>
                                                </Space>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="pet-insurance full-width bg-gray-light">
                                <div className="container">
                                    <Title level={2} className="text-green text-center mb-0 section-title">Worried About the Fine Print?</Title>
                                    <Title level={3} className="text-green text-center mb-5 mt-2">Here’s Why OSLO Is Different</Title>
                                    <Row gutter={[48, 20]} justify="space-evenly">
                                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                            <Card className="pet-insurance-box text-center p-3 full-height border-radius-15 bg-lightblue">
                                                <Image
                                                    preview={false}
                                                    className="img-fluid"
                                                    src="/images/transparency-2.png"
                                                />
                                                <Title level={3} className="text-green font-700">100% Transparency</Title>
                                                <Paragraph>No hidden clauses—straightforward and clear.</Paragraph>
                                            </Card>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                            <Card className="pet-insurance-box text-center p-3 full-height border-radius-15 bg-lightblue">
                                                <Image
                                                    preview={false}
                                                    className="img-fluid"
                                                    src="/images/step21.png"
                                                />
                                                <Title level={3} className="text-green font-700">Claims paid in less than 5 Days</Title>
                                                <Paragraph>Quick support for unexpected vet expenses.</Paragraph>
                                            </Card>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                            <Card className="pet-insurance-box text-center p-3 full-height border-radius-15 bg-lightblue">
                                                <Image
                                                    preview={false}
                                                    className="img-fluid"
                                                    src="/images/Lowest-Waiting-Period.png"
                                                />
                                                <Title level={3} className="text-green font-700">Lowest Waiting Period</Title>
                                                <Paragraph>Just 14 Days to Start Your Pet's Coverage</Paragraph>
                                            </Card>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='text-center mt-3'>
                                            <Button size="large" type="primary" className="btn-red" htmlType="submit" onClick={this.goToQouteSection}>Explore Plans Now</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="pet-insurance full-width pt-5 pb-0">
                                <div className="container">
                                    <Row gutter={[48, 20]}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Card className="pet-insurance-box full-height border-radius-15" bordered={false}>
                                                <Title level={2} className="text-green mb-0 section-title">Your Dog Gives You Unconditional Love. Give Them the Protection They Deserve</Title>
                                                <Paragraph>Imagine the relief of knowing your furry friend is covered.</Paragraph>
                                                <Image
                                                    preview={false}
                                                    className="img-fluid"
                                                    src="/images/Walking-a-Dog-1.png"
                                                />
                                            </Card>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Card className="pet-insurance-box text-center full-height border-radius-15" bordered={false}>
                                                <Image
                                                    preview={false}
                                                    className="img-fluid"
                                                    src="/images/situation-01.png"
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="pet-insurance full-width bg-gray-light">
                                <div className="container text-center ">
                                    
                                    <Row gutter={[48, 20]} justify="space-evenly">
                                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                                            <Title level={2} className="text-green text-center mb-4 section-title">Give Your Pet the Care & Protection They Deserve Today!</Title>
                                            <Paragraph className='text-size-20'>Start your pet’s journey toward a healthier, happier life with coverage you can trust. Sign up now and enjoy peace of mind for you and your furry friend.</Paragraph>
                                            <Button size="large" type="primary" className="btn-red" htmlType="submit" onClick={this.goToQouteSection}>Sign Up for Peace of Mind</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                        </div>
                    }
                </Fragment>
            </div>
        );
    }
}

export default DogMainPage;