import React, { Component, Fragment } from 'react';
import { Typography, Row, Col, Image } from 'antd';
const { Paragraph, Title } = Typography;

class welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            finalQoute: {}

        }
    }
    componentDidMount = () => {
        window.scrollTo(0,0);
        this.setState({ finalQoute: this.props.getFinalQoute() },()=>{
            console.log('final',this.state.finalQoute);
        });
        var retrievedObject = localStorage.getItem('quoteObj');
        if(retrievedObject){
            localStorage.removeItem('quoteObj');
        }
        this.props.updateGTAG("conversion");
    }
    render() {
        return (
            <Fragment>
                <Row gutter={15} justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="welcome-page text-center mt-3">
                            <Title level={2} className="text-green section-title mb-5 text-center mt-3 mb-4">
                                <Image
                                    preview={false}
                                    className="img-fluid"
                                    src="/images/icon-welcome-new.png"
                                /> Well done!
                            </Title>
                            <Title level={3} className="text-green section-title mt-4">{this.state.finalQoute?.user?.firstName.toUpperCase()} {this.state.finalQoute?.user?.lastName.toUpperCase()}, Welcome to the OSLO family !</Title>
                            {/* <Title level={3} className="text-light-green mt-0">Your policy number is : Oslo-{this.state.finalQoute.pets ? this.state.finalQoute.pets[0].tag.toUpperCase() : '' }</Title> */}
                            {
                                this.props.createdPolicies?.map((item, index) => {
                                    return (
                                        <Title level={3} className={index ? "text-light-green mt-0" : "text-light-green mt-1"}>Your pet {item.petName}'s policy number is : {item.policyCode}</Title>
                                    )
                                })
                            }

                            {/* <Title level={3} className="text-light-green mt-0">Your policy number is : {this.props.policyNumber}</Title> */}
                            <Title level={3} className="font-300 text-black">Give your pet buddy the best medical care <br /> and we’ll help with the rest.</Title>
                            {/* <div className="text-dark-green">{this.state.finalQoute?.user?.firstName} {this.state.finalQoute?.user?.lastName}</div> */}
                            <Row gutter={15} justify="center">
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <div className="section-gray mt-5">
                                        <Image
                                            preview={false}
                                            className="img-fluid"
                                            src="/images/oslo-badge-new.png"
                                        />
                                        {/* <Title level={2} className="text-green section-title mb-0 text-center">{this.state.finalQoute?.user?.firstName} {this.state.finalQoute?.user?.lastName}</Title> */}
                                        {/* <Title level={4} className="text-light-green mt-0">Your tag number: <span className="font-700">ID123456</span></Title> */}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={24} sm={20} md={20} lg={20} xl={20}>
                        <div className="section-what-next">
                            <Title level={2} className="text-green section-title mt-5 text-center">What’s next?</Title>
                            <Title level={3} className="text-muted font-300">
                                Your confirmation email with policy details and documents will be in your inbox within 24 hours.
                            </Title>
                            <Title level={3} className="text-muted font-300 mt-0">
                                You may access your online pet cloud now to:
                            </Title>
                            <div className="points-toknow">
                                <ul>
                                    <li>
                                        Submit claims & check your claim status
                                    </li>
                                    <li>
                                        Update your pet’s information, contact details & billing information
                                    </li>
                                    <li>
                                        Add another pet to your policy
                                    </li>
                                    <li>
                                        Review your policy documents
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default welcome;