import React, { Component, Fragment } from 'react';
import { Layout, Typography, Card, Row, Col, Radio, Table, Progress, Tooltip } from 'antd';
import LoginHeader from '../../components/common/loginHeader';
import LoginFooter from '../../components/common/loginFooter';
import SideMenu from '../pet_cloud/sidebar_menu_card'
import PetSelectionCard from '../pet_cloud/pet_selection_cards'
import { getClaims, getUserPets, getUserImage, getUserPolicies, GetCurrentPolicyForClaimGraphs } from '../../api/pets-api';
import moment from 'moment';


const { Paragraph, Title } = Typography;
const { Content } = Layout;


class claims extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            userPets:[],
            claims:[],
            progress:[],
            completed:[],
            selectedPetId: 0,
            currentBenefits: 0,
            currentRemainingBenefits: 0,
            currentDeductable: 0,
            currentRemainingDeductable: 0,
            totalBenefits: 0,
            totalVetApproved: 0
        };
    }
    
    getClaimsData = async () => {
        debugger;
        let resp = await getClaims(this.state.userPets[this.state.selectedPetId].id);
        console.log('claims',resp);
        let progress = [], completed = [];
        progress = resp.data.result.filter(obj=> obj.status==='new_claim' || obj.status==='in_progress' || obj.status==='in_process' || obj.status==='approved_by_vet')
        completed = resp.data.result.filter(obj=> obj.status==='accepted' || obj.status==='rejected' || obj.status==='declined_by_vet' || obj.status==='paid')

        let totBenefits = 0;
        let totVetApproved = 0;

        for(let i = 0; i < resp.data.result.length; i++){
            let obj = resp.data.result[i];
            totBenefits += obj.systemApprovedAmount && obj.systemApprovedAmount > 0 ? obj.systemApprovedAmount : 0;
            totVetApproved += obj.vetApprovedAmount && obj.vetApprovedAmount > 0 ? obj.vetApprovedAmount : 0
        }

        this.setState({claims: resp.data.result, totalBenefits: totBenefits, totalVetApproved: totVetApproved,completed, progress},()=>{
            console.log('progress',this.state.progress,this.state.completed);
        });
    }

    getCurrentPolicyData = async (userId, petId) => {
        let resp = await GetCurrentPolicyForClaimGraphs(userId, petId);
        if(resp.data.result){
            let policyInfo = resp.data.result;
            let currentDeductable = policyInfo.deductable;
            let currentRemainingDeductable = policyInfo.remainingDeductible
            let currentBenefits = policyInfo.benefits;
            let currentRemainingBenefits = policyInfo.remainingBenefits;

            this.setState({currentBenefits: currentBenefits, 
                currentRemainingBenefits: currentRemainingBenefits,
                currentDeductable: currentDeductable,
                currentRemainingDeductable: currentRemainingDeductable
            })


        } else {
            this.setState({currentBenefits: 0, 
                currentRemainingBenefits: 0,
                currentDeductable: 0,
                currentRemainingDeductable: 0
            })
        }
        console.log('current policy',resp);
    }

    getPets = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let userPets = await getUserPets();

                //let userPets = await getUserPolicies();
                console.log('userPets', userPets);
                let userPets2 = await Promise.all(userPets.data.result.map(async (item) => {
                    try{
                        if(item.image)
                        {
                            await getUserImage(item.image).then((data) => {
                                item.image = data.data.url;
                            })
                        }
                        else{
                            item.image = (item.type === 'Dog' ? "/images/dog-default-300-300.png" : "/images/cat-default-300-300.png");
                        }
                    } catch(ex) {
                        item.image = (item.type === 'Dog' ? "/images/dog-default-300-300.png" : "/images/dog-default-300-300.png");
                    }
                }));
                console.log('userpet2',userPets2);
                this.setState({ userPets: userPets.data.result }, async () => {

                    let userId = this.state.userPets[this.state.selectedPetId].userId;
                    let petId = this.state.userPets[this.state.selectedPetId].id;

                    await this.getCurrentPolicyData(userId, petId);

                    if(userPets.data.result) await this.getClaimsData();
                    // resolve(userPets.data.result)
                    // let benefit = this.state.userPets[this.state.selectedPetId].benefits == 5000 ? 20000 : this.state.userPets[this.state.selectedPetId].benefits == 10000 ? 35000 : this.state.userPets[this.state.selectedPetId].benefits == 20000 ? 70000 : 0;
                    // let currentRemaingingDeducti = this.state.userPets[this.state.selectedPetId].deductable - this.state.totalVetApproved;
                    // currentRemaingingDeducti = currentRemaingingDeducti > 0 ? currentRemaingingDeducti : 0;

                    // this.setState({currentBenefits: benefit, 
                    //     currentRemainingBenefits: benefit - this.state.totalBenefits,
                    //     currentDeductable: this.state.userPets[this.state.selectedPetId].deductable,
                    //     currentRemainingDeductable: currentRemaingingDeducti
                    // })
                })
            } catch (error) {
                console.error(error);
                reject(error);
            }
        })
    }

    async componentDidMount(){
        await this.getPets(); 

    }

    populateStatus(status, claim){
        let ste = status == 'new_claim' ? 'In Progress' : status;
        if(ste == 'rejected' && claim.vetApprovedAmount && claim.vetApprovedAmount > 0){
            ste = 'Approved, Below Deductible'
        }
        return ste;
    }

    changePetIndex = async (i) => {
        this.setState({ selectedPetId: i }, async ()=>{
            this.getClaimsData();

            let userId = this.state.userPets[this.state.selectedPetId].userId;
            let petId = this.state.userPets[this.state.selectedPetId].id;

            await this.getCurrentPolicyData(userId, petId);

            // let benefit = this.state.userPets[this.state.selectedPetId].benefits == 5000 ? 20000 : this.state.userPets[this.state.selectedPetId].benefits == 10000 ? 35000 : this.state.userPets[this.state.selectedPetId].benefits == 20000 ? 70000 : 0;
            
            // let currentRemaingingDeducti = this.state.userPets[this.state.selectedPetId].deductable - this.state.totalVetApproved;
            // currentRemaingingDeducti = currentRemaingingDeducti > 0 ? currentRemaingingDeducti : 0;
            
            // this.setState({currentBenefits: benefit, 
            //     currentRemainingBenefits: benefit - this.state.totalBenefits,
            //     currentDeductable: this.state.userPets[this.state.selectedPetId].deductable,
            //     currentRemainingDeductable: currentRemaingingDeducti
            // })
        });
    }

    render() {
        const columns = [
            {
                title: 'Claim',
                dataIndex: 'serial',
                render: (serial, record) => <Fragment key={1}>
                    <Title level={5} className="text-red font-700 p-0 mb-2">Claim</Title>
                    <Paragraph className="text-muted p-0 mb-0"> C1/{moment(record.createdAt).format('YYYY/MM')}/00000{serial} </Paragraph>
                </Fragment>
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (status, claim) => <Fragment key={2}>
                    <Title level={5} className="text-green font-700 p-0 mb-2">Status</Title>
                    <Paragraph className="text-muted p-0 mb-0">{this.populateStatus(status, claim)}</Paragraph>
                </Fragment>
            },
            {
                title: 'Billed',
                dataIndex: 'amount',
                render: amount => <Fragment key={2}>
                    <Title level={5} className="text-green font-700 p-0 mb-2">Billed</Title>
                    <Paragraph className="text-muted p-0 mb-0">AED {amount}</Paragraph>
                </Fragment>
            },
            {
                title: 'Date',
                dataIndex: 'incidentDate',
                render: incidentDate => <Fragment key={2}>
                    <Title level={5} className="text-green font-700 p-0 mb-2">Date</Title>
                    <Paragraph className="text-muted p-0 mb-0">{moment(incidentDate).format('MM/DD/YYYY')}</Paragraph>
                </Fragment>
            },
            {
                title: 'Add Doc',
                dataIndex: 'files',
                render: files => <Typography.Text ellipsis={true}><Fragment key={2}>
                    <Title level={5} className="text-green font-700 p-0 mb-2">Doc</Title>
                    {
                        files?.map(file=> <Paragraph className="text-muted p-0 mb-0">{file.name}</Paragraph>)
                    }
                </Fragment></Typography.Text>
            },
        ];

        const data = [];
        for (let i = 0; i < 20; i++) {
            data.push({
                key: i,
                claim: `Claim ${i + 1}`,
                status: "Status",
                billed: "Billed",
                date: "Date",
                add_doc: "Add Doc"
            });
        }

        return (
            <Layout className="dashboard middle claims">
                <LoginHeader />
                <Content className="pt-5 pb-5">
                    <SideMenu />
                    <div className="container">
                        <Title level={3} className="text-green font-700 mb-4">Let’s check your last claim for :</Title>

                        <Row gutter={15} justify="center">
                            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="text-center">
                                <PetSelectionCard userPets={this.state.userPets} changePetIndex={this.changePetIndex} />
                            </Col>
                            <Col xl={16} lg={16} md={16} sm={24} xs={24} className="text-center">
                                
                                <Card className="custom-shadow green mb-4" title={<Title level={4} className="text-center text-white">In Progress</Title>}>
                                    <Table className="noheader noborder" columns={columns} dataSource={this.state.progress} pagination={false} scroll={{ y: 200 }} size="small" />
                                </Card>
                                <Card className="custom-shadow green mb-5" title={<Title level={4} className="text-center text-white">Complete</Title>}>
                                    <Table className="noheader noborder" columns={columns} dataSource={this.state.completed} pagination={false} scroll={{ y: 200 }} size="small" />
                                </Card>


                                <Row gutter={15} justify="center">
                                    <Col xl={7} lg={7} md={7} sm={10} xs={24} className="text-center">
                                        <Title level={4} className="text-center font-700 text-green">Available Benefit</Title>
                                        <Progress width={180} strokeColor={"#57C5D5"} strokeWidth={10} percent={(this.state.currentRemainingBenefits / this.state.currentBenefits) * 100} type="circle" format={() => <Title level={4} className="text-muted font-300"><span className="text-light-green font-700">AED {parseFloat(this.state.currentRemainingBenefits).toFixed(0)}</span> <br /> of {this.state.currentBenefits / 1000}K</Title>} />
                                    </Col>
                                    <Col xl={7} lg={7} md={7} sm={10} xs={24} className="text-center">
                                        <Title level={4} className="text-center font-700 text-green">Remaining Deductible</Title>
                                        <Progress width={180} strokeColor={"#00749C"} strokeWidth={10} percent={(this.state.currentRemainingDeductable / this.state.currentDeductable) * 100} type="circle" format={() => <Title level={4} className="text-muted font-300"><span className="text-green font-700">AED {parseFloat(this.state.currentRemainingDeductable).toFixed(0)}</span> <br /> of {this.state.currentDeductable}</Title>} />
                                    </Col>
                                </Row>


                            </Col>
                        </Row>

                        


                        
                    </div>
                </Content>
                <LoginFooter />
            </Layout>
        );
    }
}

export default claims;